<template>
     
        <v-container class="text-center">
            <i class="fas fa-3x fa-lock"></i>
             <h1>Restricted</h1>
             <h3>You might not have sufficient permissions to access this page.</h3>
        </v-container>

     
</template>

<script>
export default {
    name: 'Restricted'
}
</script>

